import fetch from 'dva/fetch';
import { routerRedux } from 'dva/router';
import { juxt, values, keys, zip, join, map, defaultTo, compose } from 'ramda';
import { i18n as i } from '../i18n';
import { encrypt, decrypt } from './aes';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from './sessionToken';
import { showErrorCodesMessage } from './error_codes';
import { Message } from './MessageInfo';
import { objValueTrim } from './utils';
import { getConfig } from '../services/server-url-config/env';
import { getSSOLoginUrl } from './sso';
import axios from 'axios';
import { useHasRefreshedToken } from 'src/common/gobalState';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '账户或密码错误',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '参数异常',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

function checkStatus([response, body]) {
  if (response.status >= 200 && response.status <= 400) {
    return [response, body];
  }
  const errortext = codeMessage[response.status] || response.statusText;
  const error = new Error(errortext);
  error.res = body;
  error.name = response.status;
  error.response = response;
  throw error;
}

function checkStatusObject([, response]) {
  let resObj = response;
  if (typeof response === 'string') {
    try {
      resObj = JSON.parse(response);
    } catch (e) {
      const error = new Error(i('返回数据格式错误'));
      error.code = resObj.status;
      throw error;
    }
  }
  if (response instanceof Blob) {
    return resObj;
  }

  if (resObj.success || resObj.success === 1) {
    return resObj;
  } else {
    if (resObj.validation) {
      showErrorCodesMessage(JSON.parse(resObj.validation || {}).code);
    }
    const error = new Error('server error');
    // Message(resObj.code, JSON.stringify(resObj));
    error.code = resObj.status;
    error.name = response.status;
    error.errorCode = resObj.errorCode;
    error.errorCode = resObj.errorCode;
    error.res = resObj;
    console.error(error);
    throw error;
  }
}

const timeout = (promise, time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error(i('请求超时')));
    }, time);
    promise.then(resolve, reject);
  });
};

const defaultOptions = {
  time: 20000,
};

function getJson(str0) {
  let str = str0;
  if (!str) throw Error('string null');
  if (str.length < 9) throw Error('string len failed {"a":"b"}');
  while (str[str.length - 1] !== '}') {
    if (str.length < 9) {
      throw Error('string try error  {"a":"b"}');
    }
    str = str.substring(0, str.length - 1);
  }
  while (str[0] !== '{' && str.length > 0) {
    if (str.length < 9) {
      throw Error('string try catch {"a":"b"}');
    }
    str = str.substring(1, str.length);
  }
  if (str.length < 9) {
    return str;
  }
  try {
    const jsonObject = JSON.parse(str);
    return jsonObject;
  } catch (e) {
    throw Error(`string len ${str.length} convert `, e);
  }
}

const zip2 = (v) => zip(v[0], v[1]);

const makeQuery = compose(join('&'), map(join('=')), zip2, juxt([keys, values]), defaultTo({}));

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param  {boolean} [redirect] redirect to error page if catch
 * @param  {boolean} [noSession] is set session
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options, redirect = false, noSession = false) {
  if (!navigator.onLine) {
    window.location.reload(false);
    return;
  }
  const newOptions = {
    ...defaultOptions,
    ...options,
  };
  // 适配
  const queryStr = makeQuery(newOptions.query);
  if (!(newOptions.body instanceof FormData)) {
    newOptions.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...newOptions.headers,
    };
  } else {
    newOptions.headers = {
      Accept: 'application/json',
      ...newOptions.headers,
    };
  }
  newOptions.method = newOptions.method || 'POST';

  const sessionToken = getAccessToken();
  if (sessionToken && !noSession) {
    newOptions.headers.Authorization = `Bearer ${sessionToken}`;
  }
  newOptions.headers.timezone = -new Date().getTimezoneOffset();
  if (newOptions.method.toUpperCase() === 'POST' || newOptions.method.toUpperCase() === 'PUT') {
    if (!(newOptions.body instanceof FormData)) {
      if (newOptions.body) {
        newOptions.body = encrypt(JSON.stringify(objValueTrim(newOptions.body)));
      } else {
        delete newOptions.body;
        newOptions.body = encrypt('{}');
      }
    }
  } else {
    newOptions.body = undefined;
  }
  if ('primary' in newOptions.headers) {
    newOptions.headers.primary = Buffer.from(newOptions.headers.primary).toString('base64');
  }
  if ('count' in newOptions.headers) {
    newOptions.headers.count = Buffer.from(newOptions.headers.count).toString('base64');
  }
  const newUrl = join(
    '?',
    [url, queryStr].filter((item) => item),
  );

  return timeout(fetch(newUrl, newOptions), newOptions.time)
    .then((response) => {
      const { status } = response;
      if (status === 401) {
        const [hasRefreshedToken] = useHasRefreshedToken();
        if (!hasRefreshedToken) {
          return refreshTokenAndRequestAgain(url, options, redirect, noSession);
        }
      }
      return new Promise((resolve, reject) => {
        response.text().then((content) => {
          try {
            resolve([url, options, getJson(decrypt(content)), response]);
          } catch (e) {
            if (e.method === 'decrypt error') {
              const { app } = window;
              const store = app && app._store; // eslint-disable-line
              window.location.href = getSSOLoginUrl();
            }
            console.error(e);
            reject(e);
          }
        });
      });
    })
    .then((items) => {
      const now = new Date().toLocaleString();
      const label = `${now} [HTTP] fetch: ${newUrl}`;
      console.groupCollapsed(label);
      console.log('[HTTP] request:', items[1]);
      console.log('[HTTP] response:', items[2]);
      console.groupEnd();
      return Promise.resolve([items[3], items[2]]); // response and body
    })
    .then(checkStatus)
    .then(checkStatusObject)
    .catch((e) => {
      console.error('[HTTP] request 错误', e, e.name); // eslint-disable-line
      const { app } = window;
      const store = app && app._store; // eslint-disable-line
      const urlConfig = getConfig();
      if (e.message === 'The user aborted a request.') {
        if (urlConfig.env === 'development') {
          console.log('fetchAborted'); // eslint-disable-line
        }
        return;
      }
      const { dispatch } = store;
      if (e.message === 'timeout') {
        console.error('timeout'); // eslint-disable-line
        throw e;
      }
      const status = e.name;
      if (status === 403) {
        if (!(url.startsWith('/resource') || url.startsWith('/auth'))) {
          dispatch({
            type: 'login/logout',
          });
        }
        throw e;
      }
      if (status <= 504 && status >= 500 && location.href.indexOf('exception') < 0) {
        if (redirect) dispatch(routerRedux.push('/exception/500'));
        throw e;
      }
      if (status === 404 && location.href.indexOf('exception') < 0) {
        if (redirect) dispatch(routerRedux.push('/exception/404'));
        throw e;
      }
      throw e;
    });
}

async function refreshTokenAndRequestAgain(url, options, redirect, noSession) {
  const [setHasRefreshedToken] = useHasRefreshedToken();
  try {
    //第一步，刷新token
    setHasRefreshedToken(true);
    const config = getConfig();
    const urlPrefix = `${config.apiUrl.apiGatewayPrefix}${config.apiUrl.gas}`;
    const refreshTokenUrl = `${urlPrefix}/auth/token`;
    const resp = await axios.post(refreshTokenUrl, { refreshToken: getRefreshToken() });
    const { data } = resp;
    const { accessToken, refreshToken } = data.data;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    //第二部，重新调用接口
    return request(url, options, redirect, noSession);
  } catch (error) {
    const { app } = window;
    const store = app && app._store;
    const { dispatch } = store;
    dispatch({
      type: 'login/logout',
    });
    return;
  }
}

import fetch from 'dva/fetch';
import { routerRedux } from 'dva/router';
import { i18n as i } from '../i18n';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from './sessionToken';
import { objValueTrim } from './utils';
import { getConfig } from '../services/server-url-config/env';
import axios from 'axios';
import { useHasRefreshedToken } from 'src/common/gobalState';

function checkStatusObject(response, resp) {
  let resObj = response;
  if (typeof response === 'string') {
    try {
      resObj = JSON.parse(response);
    } catch (e) {
      const error = new Error(i('返回数据格式错误'));
      error.code = resObj.status;
      throw error;
    }
  }
  if (response instanceof Blob) {
    return resObj;
  }
  if (resp.status === 200) {
    return resObj;
  } else {
    const error = new Error(resObj.msg || resObj.message || resObj.errorCode || 'error');
    error.code = resObj.status;
    error.name = response.status;
    error.errorCode = resObj.errorCode;
    error.res = resObj;
    throw error;
  }
}

const timeout = (promise, time) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error(i('请求超时')));
    }, time);
    promise.then(resolve, reject);
  });
};

const defaultOptions = {
  time: 10000,
};

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param  {boolean} [redirect] redirect to error page if catch
 * @return {object}           An object containing either "data" or "err"
 */
export default async function request(url, options, redirect = true, noSession = false) {
  try {
    if (!navigator.onLine) {
      window.location.reload(false);
      return;
    }
    const newOptions = {
      ...defaultOptions,
      ...options,
    };
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
    } else {
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
    newOptions.method = newOptions.method || 'GET';
    if (newOptions.method === 'POST' || newOptions.method === 'PATCH') {
      if (!(newOptions.body instanceof FormData)) {
        newOptions.body = JSON.stringify(objValueTrim(newOptions.body)) || {};
      }
    } else {
      newOptions.body = undefined;
    }
    const sessionToken = getAccessToken();
    if (sessionToken && !noSession) {
      newOptions.headers.Authorization = `Bearer ${sessionToken}`;
    }
    newOptions.headers.timezone = -new Date().getTimezoneOffset();
    const now = new Date().toLocaleString();
    const label = `${now} [HTTP] fetch: ${url}`;
    console.groupCollapsed(label);
    console.log('[HTTP] request:', newOptions);

    const resp = await timeout(fetch(url, newOptions), newOptions.time);
    const { status } = resp;
    if (status === 401) {
      const [hasRefreshedToken] = useHasRefreshedToken();
      if (!hasRefreshedToken) {
        return refreshTokenAndRequestAgain(url, options, redirect, noSession);
      }
    }
    console.debug(resp);
    let body = null;
    if (newOptions.method === 'DELETE' || resp.status === 204) {
      body = resp.text();
    } else if (newOptions.responseType === 'blob' && resp.status !== 404 && resp.status !== 422) {
      body = await resp.blob();
    } else {
      body = await resp.json();
    }
    console.log('[HTTP] response:', body);
    await checkStatusObject(body, resp);
    return body;
  } catch (e) {
    console.error('[HTTP] request 错误', e, e.name); // eslint-disable-line
    const { app } = window;
    const store = app && app._store; // eslint-disable-line
    const urlConfig = getConfig();
    if (e.message === 'The user aborted a request.') {
      if (urlConfig.env === 'development') {
        console.log('fetchAborted'); // eslint-disable-line
      }
      return;
    }
    const { dispatch } = store;
    if (e.message === 'timeout') {
      console.error('timeout'); // eslint-disable-line
      throw e;
    }
    if (e.message === 'Failed to fetch') {
      throw new Error(i('加载异常，请刷新后再次尝试'));
    }
    const status = e.name;
    if (status === 403) {
      if (!(url.startsWith('/resource') || url.startsWith('/auth'))) {
        dispatch({
          type: 'login/logout',
        });
      }
      throw e;
    }
    if (status <= 504 && status >= 500 && location.href.indexOf('exception') < 0) {
      if (redirect) dispatch(routerRedux.push('/exception/500'));
      throw e;
    }
    if (status === 404 && location.href.indexOf('exception') < 0) {
      if (redirect) dispatch(routerRedux.push('/exception/404'));
      throw e;
    }
    throw e;
  } finally {
    console.groupEnd();
  }
}

async function refreshTokenAndRequestAgain(url, options, redirect, noSession) {
  const [setHasRefreshedToken] = useHasRefreshedToken();
  try {
    //第一步，刷新token
    setHasRefreshedToken(true);
    const config = getConfig();
    const urlPrefix = `${config.apiUrl.apiGatewayPrefix}${config.apiUrl.gas}`;
    const refreshTokenUrl = `${urlPrefix}/auth/token`;
    const resp = await axios.post(refreshTokenUrl, { refreshToken: getRefreshToken() });
    const { data } = resp;
    const { accessToken, refreshToken } = data.data;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    //第二部，重新调用接口
    return request(url, options, redirect, noSession);
  } catch (error) {
    const { app } = window;
    const store = app && app._store;
    const { dispatch } = store;
    dispatch({
      type: 'login/logout',
    });
    return;
  }
}
